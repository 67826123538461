import React, { useState, useEffect } from "react";
import "./nursingQuestionnaire.css";
import ToggleSwitch from "./ToggleSwitch";
import Questionnaire from "./Questionnaire";
import BottomQuestionnaire from "./BottomQuestionnaire";
import { MobileMenuTwo } from "../Menu";
import { useParams } from "react-router-dom";
import {
  getPatientById,
  getNQInformation,
  addNQMedicine,
  addNQPriorSurgeries,
  udpateNurseQues,
  addNQMedAllergies,
  addNQFoodAllergies,
  addNQMedHistory,
} from "../../../utils/api";
import { initialNqMedHistoryState } from "./data";
import MedicationAllergies from "./med-allergies";
import CurrentMedications from "./medications";
import FoodAllergies from "./food-allergies";
import Surgery from "./surgery";
import auth from "../../../utils/auth";

const NursingQuestionnaire = () => {
  const { id, nursing_id } = useParams();
  const [loading, setLoading] = useState(true);
  // Define States
  const [nQ, setNq] = useState([]);
  const [isMetric, setIsMetric] = useState(false);
  const [height, setHeight] = useState({ feet: "0", inches: "0", cm: "0" });
  const [weight, setWeight] = useState({ pounds: "0", kg: "0" });
  const [age, setAge] = useState("");
  const [sex, setSex] = useState("male");
  const [diagnosis, setDiagnosis] = useState("");
  const [bmi, setBmi] = useState("");
  const [surgeries, setSurgeries] = useState([]);
  const [medAllergies, setMedAllergies] = useState([]);
  const [nkfa, setNkfa] = useState(false);
  const [nkma, setNkma] = useState(false);
  const [nqHistory, setNqHistory] = useState([]);
  const [nqMedHistory, setNqMedHistory] = useState(initialNqMedHistoryState);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 825);
  const [medications, setMedications] = useState([]);
  const [recentlyTraveled, setRecentlyTraveled] = useState(false);
  // New entry form state for medications, surgeries, allergies
  const [newEntry, setNewEntry] = useState({
    medications: {
      name: "",
      dose: "",
      route: "",
      frequency: "",
      status: "Active",
    },
    surgeries: { surgery_name: "" },
    medAllergies: { medication: "", reaction: "" },
    foodAllergies: { food: "", reaction: "" },
  });
  const [isAdmin, setIsAdmin] = useState(false);

  const handleToggle = (active) => {
    setIsMetric(active);

    if (active) {
      // Convert feet and inches to cm if metric is active
      const totalInches =
        parseFloat(height.feet || 0) * 12 + parseFloat(height.inches || 0);
      const cm = (totalInches * 2.54).toFixed(2);
      setHeight({ ...height, cm, feet: "0", inches: "0" });
      // Convert pounds to kg
      const kg = (parseFloat(weight.pounds || 0) / 2.20462).toFixed(2);
      setWeight({ ...weight, kg });
    } else {
      // Convert cm to feet and inches
      const totalInches = parseFloat(height.cm || 0) / 2.54;
      const feet = Math.floor(totalInches / 12);
      const inches = (totalInches % 12).toFixed(2);
      setHeight({
        ...height,
        feet: feet.toString(),
        inches: inches.toString(),
        cm: "",
      });
      // Convert kg to pounds
      const pounds = (parseFloat(weight.kg || 0) * 2.20462).toFixed(2);
      setWeight({ ...weight, pounds });
    }
  };
  // BMI calculation logic
  useEffect(() => {
    if (isMetric) {
      // Metric BMI calculation
      const heightInMeters = parseFloat(height.cm) / 100;
      if (heightInMeters > 0 && parseFloat(weight.kg) > 0) {
        const calculatedBmi = (
          parseFloat(weight.kg) /
          heightInMeters ** 2
        ).toFixed(2);
        setBmi(calculatedBmi);
      }
    } else {
      // Imperial BMI calculation
      const totalInches =
        parseFloat(height.feet) * 12 + parseFloat(height.inches);
      if (totalInches > 0 && parseFloat(weight.pounds) > 0) {
        const calculatedBmi = (
          (parseFloat(weight.pounds) / totalInches ** 2) *
          703
        ).toFixed(2);
        setBmi(calculatedBmi);
      }
    }
  }, [height, weight, isMetric]);
  // Submit handler for form
  const handleSubmit = () => {
    const payload = {
      height: isMetric ? height.cm : `${height.feet}ft ${height.inches}in`,
      weight: isMetric ? weight.kg : weight.pounds,
      bmi: bmi || null,
      age: age || null,
      gender: sex,
      diagnosis: diagnosis,
      nursing_questionaire_id: nQ.id,
      recently_traveled: recentlyTraveled,
      NKMA: nkma,
      NKFA: nkfa,
      ...nqHistory,
      ...nqMedHistory,
    };

    udpateNurseQues(nQ.id, payload)
      .then((res) => res.json())
      .then((data) => {
        window.location.href = `/surgery-view/${id}/surgery/${nQ.surgery_id}`;
      })
      .catch((err) => {
        console.err(err);
      });
  };
  useEffect(() => {
    // Fetch user role and check if admin
    const user = auth.getUser();
    if (!user || !user.id) return;

    setIsAdmin(user.role === "Admin");

    // Fetch patient data by id
    getPatientById(id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        getNQInformation(nursing_id)
          .then((res) => res.json())
          .then((nqData) => {
            // console.log(nqData);
            setNq(nqData);
            setNkma(nqData.NKMA || false);
            setNkfa(nqData.NKFA || false);
            if (nqData.height) {
              if (nqData.height.includes("ft")) {
                const [feet, inches] = nqData.height
                  .replace("ft", "")
                  .replace("in", "")
                  .split(" ");
                setHeight({
                  feet: feet.trim(),
                  inches: inches.trim(),
                  cm: "",
                });
                setIsMetric(false);
              } else {
                setHeight({ feet: "0", inches: "0", cm: nqData.height });
                setIsMetric(true);
              }
            } else {
              setHeight({ feet: "0", inches: "0", cm: "0" });
            }

            setWeight({
              pounds: nqData.weight || "0",
              kg: nqData.weight || "0",
            });
            setAge(nqData.age || "");
            setSex(nqData.gender || "male");
            setDiagnosis(nqData.diagnosis || "");
            setBmi(nqData.bmi || "");
            setRecentlyTraveled(nqData.recently_traveled);
            setNqMedHistory({
              high_blood_pressure:
                nqData.nqMedHistories?.[0]?.high_blood_pressure || false,
              high_blood_pressure_text:
                nqData.nqMedHistories?.[0]?.high_blood_pressure_text || "",
              kidney_disease:
                nqData.nqMedHistories?.[0]?.kidney_disease || false,
              kidney_disease_text:
                nqData.nqMedHistories?.[0]?.kidney_disease_text || "",
              heart_trouble: nqData.nqMedHistories?.[0]?.heart_trouble || false,
              heart_trouble_text:
                nqData.nqMedHistories?.[0]?.heart_trouble_text || "",
              abdominal_bleeding:
                nqData.nqMedHistories?.[0]?.abdominal_bleeding || false,
              abdominal_bleeding_text:
                nqData.nqMedHistories?.[0]?.abdominal_bleeding_text || "",
              chest_pain: nqData.nqMedHistories?.[0]?.chest_pain || false,
              chest_pain_text:
                nqData.nqMedHistories?.[0]?.chest_pain_text || "",
              stroke: nqData.nqMedHistories?.[0]?.stroke || false,
              stroke_text: nqData.nqMedHistories?.[0]?.stroke_text || "",
              irregular_hb: nqData.nqMedHistories?.[0]?.irregular_hb || false,
              irregular_hb_text:
                nqData.nqMedHistories?.[0]?.irregular_hb_text || "",
              epilepsy: nqData.nqMedHistories?.[0]?.epilepsy || false,
              epilepsy_text: nqData.nqMedHistories?.[0]?.epilepsy_text || "",
              congestive_failure:
                nqData.nqMedHistories?.[0]?.congestive_failure || false,
              congestive_failure_text:
                nqData.nqMedHistories?.[0]?.congestive_failure_text || "",
              broken_bones: nqData.nqMedHistories?.[0]?.broken_bones || false,
              broken_bones_text:
                nqData.nqMedHistories?.[0]?.broken_bones_text || "",
              abnormal_cardiogram:
                nqData.nqMedHistories?.[0]?.abnormal_cardiogram || false,
              abnormal_cardiogram_text:
                nqData.nqMedHistories?.[0]?.abnormal_cardiogram_text || "",
              back_trouble: nqData.nqMedHistories?.[0]?.back_trouble || false,
              back_trouble_text:
                nqData.nqMedHistories?.[0]?.back_trouble_text || "",
              gastric_esophageal:
                nqData.nqMedHistories?.[0]?.gastric_esophageal || false,
              gastric_esophageal_text:
                nqData.nqMedHistories?.[0]?.gastric_esophageal_text || "",
              unusual_muscle_problems:
                nqData.nqMedHistories?.[0]?.unusual_muscle_problems || false,
              unusual_muscle_problems_text:
                nqData.nqMedHistories?.[0]?.unusual_muscle_problems_text || "",
              recent_cold: nqData.nqMedHistories?.[0]?.recent_cold || false,
              recent_cold_text:
                nqData.nqMedHistories?.[0]?.recent_cold_text || "",
              unexpected_fevers:
                nqData.nqMedHistories?.[0]?.unexpected_fevers || false,
              unexpected_fevers_text:
                nqData.nqMedHistories?.[0]?.unexpected_fevers_text || "",
              asthma: nqData.nqMedHistories?.[0]?.asthma || false,
              asthma_text: nqData.nqMedHistories?.[0]?.asthma_text || "",
              bad_reaction: nqData.nqMedHistories?.[0]?.bad_reaction || false,
              bad_reaction_text:
                nqData.nqMedHistories?.[0]?.bad_reaction_text || "",
              abdominal: nqData.nqMedHistories?.[0]?.abdominal || false,
              abdominal_text: nqData.nqMedHistories?.[0]?.abdominal_text || "",
              relatives_with_bad_reaction:
                nqData.nqMedHistories?.[0]?.relatives_with_bad_reaction ||
                false,
              relatives_with_bad_reaction_text:
                nqData.nqMedHistories?.[0]?.relatives_with_bad_reaction_text ||
                "",
              diabetes: nqData.nqMedHistories?.[0]?.diabetes || false,
              diabetes_text: nqData.nqMedHistories?.[0]?.diabetes_text || "",
              motion_sickness:
                nqData.nqMedHistories?.[0]?.motion_sickness || false,
              motion_sickness_text:
                nqData.nqMedHistories?.[0]?.motion_sickness_text || "",
              yellow_jaundice:
                nqData.nqMedHistories?.[0]?.yellow_jaundice || false,
              yellow_jaundice_text:
                nqData.nqMedHistories?.[0]?.yellow_jaundice_text || "",
              neuro: nqData.nqMedHistories?.[0]?.neuro || false,
              neuro_text: nqData.nqMedHistories?.[0]?.neuro_text || "",
              hepatitis: nqData.nqMedHistories?.[0]?.hepatitis || false,
              hepatitis_text: nqData.nqMedHistories?.[0]?.hepatitis_text || "",
              hypertension: nqData.nqMedHistories?.[0]?.hypertension || false,
              hypertension_text:
                nqData.nqMedHistories?.[0]?.hypertension_text || "",
              fainting: nqData.nqMedHistories?.[0]?.fainting || false,
              fainting_text: nqData.nqMedHistories?.[0]?.fainting_text || "",
              bleeding_problems:
                nqData.nqMedHistories?.[0]?.bleeding_problems || false,
              bleeding_problems_text:
                nqData.nqMedHistories?.[0]?.bleeding_problems_text || "",
              heart_disease: nqData.nqMedHistories?.[0]?.heart_disease || false,
              heart_disease_text:
                nqData.nqMedHistories?.[0]?.heart_disease_text || "",
              dentures: nqData.nqMedHistories?.[0]?.dentures || false,
              dentures_text: nqData.nqMedHistories?.[0]?.dentures_text || "",
              smoke: nqData.nqMedHistories?.[0]?.smoke || false,
              smoke_text: nqData.nqMedHistories?.[0]?.smoke_text || "",
              teeth_caps: nqData.nqMedHistories?.[0]?.teeth_caps || false,
              teeth_caps_text:
                nqData.nqMedHistories?.[0]?.teeth_caps_text || "",
              exercise: nqData.nqMedHistories?.[0]?.exercise || false,
              exercise_text: nqData.nqMedHistories?.[0]?.exercise_text || "",
              drink_alcohol: nqData.nqMedHistories?.[0]?.drink_alcohol || false,
              drink_alcohol_text:
                nqData.nqMedHistories?.[0]?.drink_alcohol_text || "",
              physical_limitations:
                nqData.nqMedHistories?.[0]?.physical_limitations || false,
              physical_limitations_text:
                nqData.nqMedHistories?.[0]?.physical_limitations_text || "",
            });
            setNqHistory({
              sleep_apnea: nqData.sleep_apnea || false,
              sleep_apnea_text: nqData.sleep_apnea_text || "",
              latex_allergy: nqData.latex_allergy || false,
              latex_allergy_text: nqData.latex_allergy_text || "",
              last_period: nqData.last_period || "",
              period_na: nqData.period_na || false,
              pregnant: nqData.pregnant || false,
              pregnant_text: nqData.pregnant_text || "",
            });
            // console.log("NQ Data:", nqData);
            setMedications(nqData.nqMedications || []);
            setMedAllergies(nqData.nqMedAllergies || []);
            setFoodAllergies(nqData.nqFoodAllergies || []);
            setSurgeries(nqData.nqpriorSurgeries || []);

            setLoading(false);
          })
          .catch((err) =>
            console.error("Error fetching Nursing Questionnaire:", err)
          );
      })
      .catch((err) => console.error("Error fetching patient data:", err));
  }, [id]);

  // Effect to listen to window resize and update state
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 825);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Render a different form based on screen size
  if (isSmallScreen) {
    return (
      <>
        <MobileMenuTwo />
      </>
    );
  }

  // Handle form input changes
  const handleInputChange = (e, type, field = null) => {
    const value = e.target.value;

    setNewEntry((prev) => ({
      ...prev,
      [type]: field ? { ...prev[type], [field]: value } : value,
    }));
  };
  // Add new entry (medications, surgeries, allergies)
  const addEntry = (type) => {
    if (type === "medications") {
      const medicationEntry = {
        name: newEntry.medications.name,
        dose: newEntry.medications.dose,
        route: newEntry.medications.route,
        frequency: newEntry.medications.frequency,
        status: newEntry.medications.status,
        nursing_questionaire_id: nQ.id,
      };

      addNQMedicine(medicationEntry)
        .then((res) => res.json())
        .then((data) => {
          if (data.newNQMedications && data.newNQMedications.id) {
            setMedications((prev) => [...prev, data.newNQMedications]);
          }
        })
        .catch((err) => console.error("Error adding medication:", err));
    } else if (type === "surgeries") {
      if (!nQ || !nQ.id) {
        console.error(
          "nQ is not defined or ID is missing. Cannot add surgery."
        );
        return;
      }

      const surgeryEntry = {
        surgery_name: newEntry.surgeries.surgery_name,
        nursing_questionaire_id: nQ.id,
      };

      addNQPriorSurgeries(surgeryEntry)
        .then((res) => res.json())
        .then((data) => {
          if (data.newPriorSurgeries && data.newPriorSurgeries.id) {
            setSurgeries((prev) => [...prev, data.newPriorSurgeries]);
            setNewEntry((prev) => ({
              ...prev,
              surgeries: { surgery_name: "" },
            }));
          }
        })
        .catch((err) => console.error("Error adding surgery:", err));
    } else if (type === "medAllergies") {
      newEntry.medAllergies.nursing_questionaire_id = nQ.id;

      addNQMedAllergies(newEntry.medAllergies)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.newNQMedAllergies && data.newNQMedAllergies.id) {
            newEntry.medAllergies.id = data.newNQMedAllergies.id;

            setMedAllergies((prev) => [...prev, data.newNQMedAllergies]);
            setNewEntry((prev) => ({
              ...prev,
              medAllergies: { medication: "", reaction: "" },
            }));
          } else {
            console.error("Invalid API response:", data);
          }
        })
        .catch((err) => console.error("Error adding medAllergies:", err));
    } else if (type === "foodAllergies") {
      newEntry.foodAllergies.nursing_questionaire_id = nQ.id;

      addNQFoodAllergies(newEntry.foodAllergies)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.newNQFoodAllergies && data.newNQFoodAllergies.id) {
            newEntry.foodAllergies.id = data.newNQFoodAllergies.id;

            setFoodAllergies((prev) => [...prev, data.newNQFoodAllergies]);
            setNewEntry((prev) => ({
              ...prev,
              foodAllergies: { food: "", reaction: "" },
            }));
          } else {
            console.error("Invalid API response:", data);
          }
        })
        .catch((err) => console.error("Error adding foodAllergies:", err));
    }
    const addNqMedHistory = (newMedHistoryEntry) => {
      if (!nQ || !nQ.id) {
        console.error(
          "nQ is not defined or ID is missing. Cannot add med history."
        );
        return;
      }

      // Dynamically generate the payload from nqMedHistory object
      const payload = Object.keys(nqMedHistory).reduce((acc, key) => {
        const value = nqMedHistory[key];
        const textKey = `${key}_text`;
        acc[key] = value || false;
        acc[textKey] = nqMedHistory[textKey] || "";
        return acc;
      }, {});

      // Send the payload via the API call
      addNQMedHistory(payload)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.newMedHistory && data.newMedHistory.id) {
            // Update the medical history state with the new entry
            setNqMedHistory((prev) => [...prev, data.newMedHistory]);

            setNewEntry(initialNqMedHistoryState);
          } else {
            console.error("Invalid API response:", data);
          }
        })
        .catch((err) => {
          console.error("Error adding medical history:", err);
        });
    };
  };
  const updateNQ = (e, type) => {
    const value = e.target.checked;

    if (type === "medAllergies") {
      const payload = { NKMA: value };
      udpateNurseQues(nQ.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setNkma(value);
        })
        .catch((err) => console.log(err));
    } else if (type === "foodAllergies") {
      const payload = { NKFA: value };
      udpateNurseQues(nQ.id, payload)
        .then((res) => res.json())
        .then((data) => {
          setNkfa(value);
        })
        .catch((err) => console.log(err));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Nursing Questionnaire */}
      <div className="patient-grid-container">
        <div className="patient-first-column"></div>
        <div className="patient-second-column patient-info-card">
          <div className="nurse-ques-form">
            <div className="nurse-ques-container">
              <h1>Nursing Questionnaire</h1>
              <div className="border-bottom"></div>

              {/* Height, Weight, and Toggle */}
              <div className="nurse-ques-form-row">
                <div className="nurse-ques-form-group">
                  <label htmlFor="height">Height</label>
                  <div className="nurse-ques-input-group">
                    {!isMetric ? (
                      <>
                        <div className="nurse-ques-field">
                          <input
                            id="height-feet"
                            type="text"
                            placeholder="ft."
                            value={height.feet}
                            onChange={(e) =>
                              setHeight({ ...height, feet: e.target.value })
                            }
                          />
                          <label className="below-label" htmlFor="height-feet">
                            Feet
                          </label>
                        </div>
                        <div className="nurse-ques-field">
                          <input
                            type="text"
                            placeholder="inch."
                            id="height-inches"
                            value={height.inches}
                            onChange={(e) =>
                              setHeight({ ...height, inches: e.target.value })
                            }
                          />
                          <label
                            className="below-label"
                            htmlFor="height-inches"
                          >
                            Inches
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="nurse-ques-field">
                        <input
                          id="height-cm"
                          type="text"
                          placeholder="cm"
                          value={height.cm}
                          onChange={(e) =>
                            setHeight({ ...height, cm: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="height-cm">
                          Centimeters
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="nurse-ques-form-group">
                  <label htmlFor="weight">Weight</label>
                  <div className="nurse-ques-field">
                    {!isMetric ? (
                      <>
                        <input
                          id="weight"
                          type="text"
                          placeholder="lbs."
                          value={weight.pounds}
                          onChange={(e) =>
                            setWeight({ ...weight, pounds: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="weight">
                          Pounds
                        </label>
                      </>
                    ) : (
                      <>
                        <input
                          id="weight-kg"
                          type="text"
                          placeholder="kg"
                          value={weight.kg}
                          onChange={(e) =>
                            setWeight({ ...weight, kg: e.target.value })
                          }
                        />
                        <label className="below-label" htmlFor="weight-kg">
                          Kilograms
                        </label>
                      </>
                    )}
                  </div>
                </div>

                <div className="nurse-ques-form-group switch-group">
                  <ToggleSwitch id="toggle" onToggle={handleToggle} />
                </div>
              </div>

              {/* BMI, age, sex, diagnosis */}
              <div className="nurse-ques-form-row bmi">
                <div className="nurse-ques-form-group bmi-group">
                  <label htmlFor="bmi">Calculated BMI</label>
                  <input
                    type="text"
                    disabled
                    className="bmi-input"
                    id="bmi"
                    value={bmi || ""}
                  />
                </div>
                <div className="nurse-ques-form-group">
                  <label htmlFor="age">Age</label>
                  <input
                    type="number"
                    id="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                <div className="nurse-ques-form-group">
                  <label htmlFor="sex">Sex</label>
                  <select
                    id="sex"
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              <div className="nurse-ques-form-row">
                <div className="nurse-ques-form-group diagnosis-group">
                  <label htmlFor="diagnosis">Diagnosis</label>
                  <input
                    type="text"
                    id="diagnosis"
                    value={diagnosis}
                    onChange={(e) => setDiagnosis(e.target.value)}
                  />
                </div>
              </div>

              {/* Current Medications */}
              <div className="table-container">
                <h2 className="table-label">Current Medications</h2>
                <table className="medications-table">
                  <thead>
                    <tr>
                      <th className="table-label">Name</th>
                      <th className="table-label">Dose</th>
                      <th className="table-label">Route</th>
                      <th className="table-label">Frequency</th>
                      <th className="table-label">Status</th>
                      <th className="table-label">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medications.map((med, index) => (
                      <CurrentMedications
                        med={med}
                        key={med.id}
                        medications={medications}
                        setMedications={setMedications}
                      />
                    ))}
                    <tr>
                      <td data-label="Name">
                        <input
                          type="text"
                          name="name"
                          value={newEntry.medications.name}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "name")
                          }
                        />
                      </td>
                      <td data-label="Dose">
                        <input
                          type="text"
                          name="dose"
                          value={newEntry.medications.dose}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "dose")
                          }
                        />
                      </td>
                      <td data-label="Route">
                        <input
                          type="text"
                          name="route"
                          value={newEntry.medications.route}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "route")
                          }
                        />
                      </td>
                      <td data-label="Frequency">
                        <input
                          type="text"
                          name="frequency"
                          value={newEntry.medications.frequency}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "frequency")
                          }
                        />
                      </td>
                      <td data-label="Status" className="nowrap">
                        <select
                          name="status"
                          value={newEntry.medications.status}
                          onChange={(e) =>
                            handleInputChange(e, "medications", "status")
                          }
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </td>
                      <td data-label="" className="flex-center">
                        <button
                          className="medication-add-btn"
                          onClick={() => addEntry("medications")}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Prior Surgeries */}
              <div>
                <h3 className="table-label">Prior Surgeries</h3>
                <table className="surgeries-table">
                  <tbody>
                    {surgeries.map((surgery, index) => (
                      <Surgery
                        key={surgery.id}
                        surgery={surgery}
                        surgeries={surgeries}
                        setSurgeries={setSurgeries}
                      />
                    ))}
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="Enter surgery"
                          value={newEntry.surgeries.surgery_name}
                          onChange={(e) =>
                            handleInputChange(e, "surgeries", "surgery_name")
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="surgery-add-btn"
                          onClick={() => {
                            addEntry("surgeries");
                          }}
                          disabled={!nQ || !nQ.id}
                        >
                          Add
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Questionnaire */}
              <Questionnaire
                nqMedHistory={nqMedHistory}
                nQ={nQ}
                setRecentlyTraveled={setRecentlyTraveled}
                recentlyTraveled={recentlyTraveled}
              />

              {/* Medication Allergies */}

              <div className="allergy-heading">
                <h3 className="table-label">Medication Allergies</h3>
                {medAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nkma}
                      onChange={(e) => {
                        setNkma(e.target.checked);
                        if (e.target.checked) {
                          updateNQ(e, "medAllergies");
                        }
                      }}
                    />
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nkma ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Medication</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {medAllergies.map((allergy, index) => (
                    <MedicationAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setMedAllergies={setMedAllergies}
                      medAllergies={medAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Medication:</label>
                      <input
                        type="text"
                        name="medication"
                        value={newEntry.medAllergies.medication}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "medication")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.medAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "medAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("medAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Food Allergies */}

              <div className="allergy-heading">
                <h3 className="table-label">Food Allergies</h3>
                {foodAllergies.length === 0 ? (
                  <label>
                    <input
                      type="checkbox"
                      checked={nkfa}
                      onChange={(e) => {
                        setNkfa(e.target.checked);
                        handleInputChange(e, "foodAllergies", "nka");
                        updateNQ(e, "foodAllergies");
                      }}
                    />{" "}
                    NKA
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <table
                className={
                  nkfa ? "medications-table dimmed" : "medications-table"
                }
              >
                <thead>
                  <tr>
                    <th className="table-label">Food</th>
                    <th className="table-label">Reaction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {foodAllergies.map((allergy, index) => (
                    <FoodAllergies
                      key={allergy.id}
                      allergy={allergy}
                      setFoodAllergies={setFoodAllergies}
                      foodAllergies={foodAllergies}
                    />
                  ))}
                  <tr>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Food:</label>
                      <input
                        type="text"
                        name="food"
                        value={newEntry.foodAllergies.food}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "food")
                        }
                      />
                    </td>
                    <td className="medications-col-width">
                      <label style={{ display: "none" }}>Reaction:</label>
                      <input
                        type="text"
                        name="reaction"
                        value={newEntry.foodAllergies.reaction}
                        onChange={(e) =>
                          handleInputChange(e, "foodAllergies", "reaction")
                        }
                      />
                    </td>
                    <td className="flex-center">
                      <button
                        className="medication-add-btn"
                        onClick={() => addEntry("foodAllergies")}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* Bottom Questionnaire */}
              <BottomQuestionnaire
                nqHistory={nqHistory}
                nQ={nQ}
                setNqHistory={setNqHistory}
              />

              {/* Submit Button */}
              <div className="ques-submit-btn-container">
                <button className="ques-submit-btn" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NursingQuestionnaire;
